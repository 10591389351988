import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"

import SEO from "../components/seo"

const Page = () => (
  <Layout>
    <SEO title="History" keywords={[`New Hope Pet Center History`, `New Hope`, `Pet Center`, `Pet Grooming`, `Pet Supplies`]} />
    <div>
      <div className="mw8 center">
          <article className="pb5">
             <div className="ph3 ph4-m ph5-l">
                <div className="f5 f3-m lh-copy">
                   <div className="cf dt-l w-100 pv4">
                      <div className="dtc-l v-mid mw6 pr3-l"><img className="w-100" src={require("../images/history1.png")} alt="Ron & Sue"/></div>
                      <div className="dtc-l v-mid f6 f5-m f4-l measure-l">
                        <h1 className="f3 f2-ns lh-title fw9 mb3 mt0 pt3 bb-ns b--light-gray bw2">Our History</h1>
                         <p className="measure pv4-l center">
                            <span className="fw9 fw4-ns">On April 11, 1981, Feathered Friends, Etc. </span> opened its doors in what is now the Delray Shopping Center, Bridge Street, New Hope. Feathered Friends, Etc. remained in that center until 1990, when we moved to Logan Square. After a few years, Feathered Friends, Etc. evolved into New Hope Pet Center.
                         </p>
                      </div>
                   </div>
                   <div className="cf dn">
                      <div className="fl w-100 w-50-l">
                         <p className="f5 pv4">
                            During high tides, water would flow directly into the pools from the nearby ocean, recycling the <span className="db f4 f3-m f1-l fw6 measure lh-title">two million US gallons of water in about an hour. </span>
                         </p>
                      </div>
                      <div className="fl w-100 w-50-l">
                         <p className="f5">During low tides, a powerful turbine water pump, built inside a cave at sea level, could be switched on from a control room and could fill the tanks at a rate of 6,000 US gallons a minute, recycling all the water in five hours.</p>
                      </div>
                   </div>
                   <div className="cf dt">
                      <div className="dtc v-top pa3">
                        <img className="w-auto" src={require("../images/history2.jpg")} alt="New Hope Pet Center History - Sue Grooming 1"/>
                      </div>
                      <div className="dtc v-top pa3">
                        <img className="w-auto" src={require("../images/history3.jpg")} alt="New Hope Pet Center History - Sue Grooming 2"/>
                      </div>
                      <div className="dtc v-top pa3">
                        <img className="w-auto" src={require("../images/history4.jpg")} alt="New Hope Pet Center History - Stella Hudnall"/>
                      </div>
                      <div className="dtc v-top pa3">
                        <img className="w-auto" src={require("../images/history5.jpg")} alt="New Hope Pet Center History - The Coeyman's"/>
                      </div>
                   </div>
                </div>
             </div>
          </article>
      </div>
    </div>

  </Layout>
)

export default Page
